#design-top-nav {
    text-align: center;
    padding-top: 25px;
}

#design-title {
    color: var(--blue-black);
    font-weight: 500;
    font-size: 18px;
}

#design-play-text {
    left: 22px;
}

#design-undo-text {
    left: 17.5px;
}

#design-last-text {
    left: 21px;
}

#design-3d-text {
    left: 9px;
}

#design-map-text {
    left: 20px;
}

#design-print-text {
    left: 19px;
}

#design-floorplans-text {
    left: 2.5px;
}

#design-projects-text {
    left: 9px;
}

#design-freeze-text {
    left: 11px;
}

#design-unfreeze-text {
    left: 9px;
}

#design-zoom-fit-text {
    left: -2px;
    right: 0px;
}

#design-detect-text {
    left: 14px;
}

#left-propagation-divider {
    margin: 0 5px 17px -5px;
}

#right-print-divider {
    margin: 0 3px 17px -3px;
}

#right-freeze-divider {
    /* use when add print icon */
    /* margin: 0 -12px 17px 0px; */
    margin: 0 3px 17px 0px;
}

#right-unfreeze-divider {
    /* use when add print icon */
    /* margin: 0 -14px 17px 10px; */
    margin: 0 1px 17px 10px;
}

#zoom-fit-wrapper {
    right: -8px;
}


