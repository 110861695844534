.project-card {
    max-width: 900px;
    box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
    padding: 5px 20px;
    border-radius: 4px;
    height: 100px;
    background-color: var(--white);
    border: solid 1px var(--border-grey);
    margin: 0 auto 15px auto;
    position: relative;
}

.project-card-summary {
    line-height: 0.5;
    height: 100%;
    display: block;
    overflow: hidden;
    padding-top: 4px;
}

.project-name {
    font-size: 20px;
    white-space: nowrap;
    font-weight: 600;
}

.project-date {
    font-size: 16px;
    font-weight: 500;
    color: var(--blue-black);
    opacity: 0.7;
}

.project-card-icon-div {
    float: right;
    padding-top: 29px;
    width: 140px;
    text-align: right;
    height: 100%;
}

.project-card-icon-div a {
    font-size: 20px;
}

.project-delete {
    margin-left: 25px;
}

