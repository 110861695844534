#project-form {
    width: 600px;
    margin: 0 auto;
    color: var(--blue-black);
}

.project-form-input {
    width: 100%;
    border: solid 1px var(--border-grey);
    border-radius: 4px;
    padding: 7px;
    color: var(--blue-black);
    line-height: 1.6;
    background-color: var(--white);
    outline: none;
    font-size: 16px;
    height: 40px;
}

textarea,
#file-drop-zone {
    width: 100%;
    border: solid 1px var(--border-grey);
    background-color: var(--white);
    border-radius: 4px;
    font-size: 16px;
}

textarea {
    height: 80px;
    padding: 7px;
}

#file-drop-zone {
    height: 150px;
    text-align: center;
    padding: 0;
}

select {
    width: 100%;
    border: solid 1px var(--border-grey);
    background-color: var(--white);
    height: 40px;
    font-size: 16px;
    padding: 7px;
    border-radius: 4px;
}

#drop-file {
    color: var(--text-grey);
    opacity: 0.6;
}

.detail {
    font-weight: 600;
    opacity: 0.8;
}

#select-file-input {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    height: 150px;
    width: 700px;
    border: solid 1px red;
    display: block;
}

#select-file-wrapper {
    padding-top: 43px;
    position: relative;
    margin-top: 0;
}

#project-submit {
    padding: 6px;
    background-color: var(--blue);
    color: var(--white);
    border-radius: 4px;
    box-shadow: 1px 1px 1.5px var(--shadow-grey);
    margin-bottom: 15px;
    width: 100%;
    font-weight: 550;
    height: 40px;
    font-size: 16px;
  }
  
#project-submit:hover {
    background-color: var(--dark-blue);
}

#project-form-footer {
    text-align: center;
}

.cancel-form:hover {
    color: var(--text-dark-grey);
}

#image-view {
    max-width: 700px;
    height: auto;
    min-height: 100px;
    margin: 30px auto 0 auto;
    padding-bottom: 150px;
}

#project-img, #propagation-img {
    width: 100%;
    height: 100%;
}

#propagation-img {
    z-index: 999;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5
}

#image-div {
    position: absolute;
    top: 0;
    left: 0;
}

#delete-project-img {
    margin-bottom: 5px;
    display: inline-block;
}

#file-message {
    opacity: 0.75;
}

.form-err-message {
    text-align: center;
}


