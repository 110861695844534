#floorplan-top-nav {
    text-align: center;
    padding-top: 25px;
}

#floorplan-title {
    color: var(--blue-black);
    font-weight: 500;
    font-size: 18px;
}

.nav-message {
    color: (--blue-black);
    padding-top: 25px;
    display: inline-block;
    opacity: 0.7;
}

#floorplan-save-text {
    left: 19px;
}

#floorplan-crop-text {
    left: 19px;
}

#floorpan-rotate-text {
    left: 15px;

}

#floorplan-cancel-text {
    left: 13px;
}

#floorplan-projects-text {
    left: 9px;
}

#left-rotate-divider {
    margin: 0 -5px 17px 5px;
}