#design-left-nav {
    background-color: var(--background-grey);
    border-right: solid 1px #dadce0;
    width: 130px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 75px;
    text-align: center;
}

#design-logo {
    height: 35px;
    margin: 20px 0 20px 0;
}

#design-wifi-icon {
  margin-top: 20px;
}

#design-legend {
    width: 100px;
    display: block;
    margin: 30px auto 0 auto;
}

.toggle-label {
    color: var(--blue-black);
    margin: 10px 0 5px 0;
}

/* box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    outline: none;
  }

  .switch input {
    position: absolute;
    top: -99999px;
    left: -99999px;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: var(--blue);
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }