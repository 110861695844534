/* 
https://www.color-hex.com/color/0a66c2 
https://www.color-hex.com/color/e1e3e6
https://materialdesignicons.com/
logo-blue = #23329A
*/

:root {
    /* for buttons and body text including messages*/
    --blue: #0a66c2; 
    /* for text and button hover (*/
    --dark-blue: #074787;
    /* for form titles */
    --blue-black: #031e3a;
    --shadow-grey: #7b7c7c;
    --border-grey: #e1e3e6;
    --background-grey: #f9f9fa;
    --link-background-grey: #e0e0e1;
    --text-grey: #626363;
    /* for hover */
    --text-dark-grey: #161617;
    --white: #ffffff;
    --red: #ff0000;
    --green: #008000;
}

.red {
    color: var(--red);
}

.green {
    color: var(--green);
}

.blue {
    color: var(--blue)
}

.blue-link {
    color: var(--blue);
    font-weight: 600;
}

.grey-message-text {
    color: var(--text-grey);
    font-weight: 500;
}

.blue-link:hover {
    color: var(--dark-blue)
}

a {
    text-decoration: none;
}

a,
button
{
    cursor: pointer;
}





