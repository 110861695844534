.floorplans-card {
    width: 350px;
    box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
    padding: 24px;
    border-radius: 4px;
    margin: 20px auto;
    background: var(--white);
}

.floorplans-header {
    font-size: 25px;
    color: var(--blue-black);
    margin-bottom: 20px;
    font-weight: 450;
}

.floorplans-input {
    display: inline-block;
    margin-bottom: 10px;
    margin-right: 10px;
    width: 242px;
    padding: 10px;
    border: none;
    border: 1px solid var(--border-grey);
    outline: none;
    background-color: var(--white);
    font-size: 16px;
    border-radius: 4px;
    height: 40px;
}

.floorplans-submit {
    padding: 7px 6px 6px 6px;
    background-color: var(--blue);
    color: var(--white);
    border-radius: 4px;
    box-shadow: 1px 1px 1.5px var(--shadow-grey);
    font-size: 16px;
    width: 50px;
    font-weight: 550;
    height: 39px;
    display: inline-block;
    position: relative;
    text-align: center;
}

.floorplans-submit:hover {
    background-color: var(--dark-blue);
}

.floorplans-file-input {
    position: absolute;
    width: 50px;
    height: 40px;
    top: 0;
    left: 0;
    opacity: 0;
}