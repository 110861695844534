.icon-options-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    background: rgba(128,128,128,0.7);
    width: 100%;
    height: 100%;
}

.icon-options-card {
    position: absolute;
    width: 180px;
    /* height: 251px; */
    box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
    padding: 15px;
    border-radius: 4px;
    /* margin: 20px auto; */
    background: var(--white);
}


.icon-options-header {
    font-size: 22px;
    color: var(--blue-black);
    margin-bottom: 20px;
    font-weight: 450;
}

.icon-options-subheader {
    font-size: 15px;
    display: block;
    font-weight: 350;
    opacity: 0.8;
    color: var(--blue-black);
    margin-bottom: 15px;
}

.icon-options-list-card {
    background-color: white;
    opacity: 1;
    max-width: 153px;
    box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
    padding: 1px 8px 2px 8px;
    border-radius: 4px;
    height: 40px;
    background-color: var(--white);
    border: solid 1px var(--border-grey);
    /* margin: 0 auto 15px auto; */
    margin-bottom: 10px;
    position: relative;
}

.icon-options-list-summary {
    line-height: 1.9;
    height: 100%;
    display: block;
    overflow: hidden;
    padding-top: 4px;
}

.icon-options-list-name {
    font-size: 16px;
    white-space: nowrap;
    font-weight: 600;
    margin: 0;
}

.icon-input {
    display: inline-block;
    margin-bottom: 10px;
    margin-right: 10px;
    width: 153px;
    padding: 10px;
    border: none;
    border: 1px solid var(--border-grey);
    outline: none;
    background-color: var(--white);
    font-size: 16px;
    border-radius: 4px;
    height: 40px;
}

.icon-submit {
    padding: 7px 6px 6px 6px;
    background-color: var(--blue);
    color: var(--white);
    border-radius: 4px;
    box-shadow: 1px 1px 1.5px var(--shadow-grey);
    font-size: 16px;
    width: 153px;
    font-weight: 550;
    height: 39px;
    display: inline-block;
    position: relative;
    text-align: center;
}

