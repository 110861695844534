@keyframes spinner {
    0% {
      transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100% {
      transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
  }

  .spinner-container {
    position: relative;
  }

  .spinner::before {
    animation: 1.5s linear infinite spinner;
    animation-play-state: inherit;
    border: solid 5px #cfd0d1;
    border-bottom-color: #1c87c9;
    border-radius: 50%;
    content: "";
    position: absolute;
    top: 10%;
    left: 10%;
    transform: translate3d(-50%, -50%, 0);
    will-change: transform;
  }

  #projects-spinner-container {
    height: 40px;
    width: 40px;
    margin: 85px auto;
  }

  #projects-spinner::before {
    height: 40px;
    width: 40px;
  }

  #floorplan-spinner-container {
    height: 20px;
    width: 20px;
    margin: 25px auto 0 auto;
  }

  #floorplan-spinner::before {
      height: 20px;
      width: 20px;
  }

  #design-spinner-container {
    height: 80px;
    width: 80px;
    position: absolute;
    z-index: 99999;
  }

  #design-spinner::before {
    height: 80px;
    width: 80px;
    border-bottom-color: #1c87c9;
  }

  #design-spinner-background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
    background: rgba(128,128,128,0.7);
    width: 100%;
    height: 100%;
  }
