#top-nav {
    height: 74px;
    padding-top: 20px;
    padding-left: 40px;
    background-color: #fff;
    border-bottom: solid 2px #dadce0;
  }
  
  #nav-logo {
    float: left;
    width: 30px;
    margin-right: 12px;
    margin-top: -5px;
  }
  
  #nav-brand-name {
    font-weight: 400;
    font-size: 30px;
    color: #1663ab;
  }
  
  #sign-out {
    float: right;
    margin-right: 40px;
    font-size: 18px;
  }
  
  #sign-out > button {
    padding: 6px;
    background-color: #1a73e8;
    color: #fff;
    border-radius: 4px;
    box-shadow: 1px 1px 1.5px #888888;
    margin-bottom: 15px;
    font-size: 18px;
  }
  
  #account-icon-div {
    float: right;
    font-size: 30px;
    color: #fff;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.54);
    opacity: 0.8;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    margin-right: 41px;
  }
  
  #account-icon {
    text-align: center;
    width: 100%;
    height: 100%;
    vertical-align: top;
    line-height: 25px;
  }
  
  #account-nav-div {
    border: solid 2px #dadce0;
    background-color: #fff;
    /* float: right; */
    font-size: 19px;
    position: absolute;
    top: 73px;
    right: 40px;
    line-height: 2.5;
    border-radius: 9px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
    z-index: 10;
    color: #5f6368;
  }
  
  .user-request-card {
    /* margin: 20px 0; */
    width: 100%;
    border: solid 2px #dadce0;
    padding: 15px;
    border-radius: 9px;
    /* box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.07); */
    box-shadow: inset 1px 1px 0 rgb(0 0 0 / 10%), inset 0 -1px 0 rgb(0 0 0 / 7%);
    background-color: #fff;
    color: 0, 0, 0, 0.87;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.15;
  }
  
  .overlay-div {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 3;
  }
  
  #account-nav {
    padding: 20px;
  }
  
  @media screen and (max-width: 1100px) {
    #top-nav {
      padding-left: 15px;
    }
  
    #account-icon-div {
      margin-right: 15px;
    }
  }
  