#design-container {
  width: 100%;
  height: 100%;
  position: relative;
}

#design-body-container {
  background-color: var(--blue-black);
  width: 100%;
  height: 100%;
  padding-left: 130px;
}

.item {
  position: absolute;
  background: red;
  min-height: 20px;
  min-width: 20px;

}

#image-div-wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.ap-label {
  text-align: center;
  position: absolute;
  width: 40px;
  top: 5px;
  left: 0;
  text-align: center;
  z-index: 9;
  font-size: 16px;
  color: black;
  font-weight: 600;
}

.transmitter {
  width: 100%;
  height: 100%;
}

/* modals used in design display */

.design-modal-footer {
  text-align: center;
}

.design-cancel-form {
  color: var(--blue)
}

.design-cancel-form:hover {
  color: var(--dark-blue);
  font-weight: 500;
}

.design-cancel-icon-div {
  position: absolute;
  top: 2px;
  right: 2px;

}
.design-cancel-icon-div img {
  opacity: 0.45;
}

