#project-nav {
    margin-bottom: 40px;
}

#project-nav-logo-link {
    display: block;
    width: 148px;
    margin: 0 auto;
}

#project-nav-logo {
    height: 60px;
    margin: 0 auto;
    display: block;
    padding-top: 10px;
}

#project-nav-signout {
    left: 18px;
}

#project-nav-signout-text {
    left: 9px;
}

#project-nav-account-text {
    left: 9px;
}

#project-nav-refresh-text {
    left: 11px;
}

#project-nav-add-text {
    left: 19.5px;
}

