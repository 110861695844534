.auth-card {
    width: 350px;
    box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
    padding: 24px;
    border-radius: 4px;
    margin: 0 auto;
    background: var(--white);
}

.auth-form-header {
    font-size: 25px;
    color: var(--blue-black);
    margin-bottom: 20px;
    font-weight: 450;
}

.auth-input {
    display: inline-block;
    margin-bottom: 10px;
    width: 100%;
    padding: 10px;
    border: none;
    border: 1px solid var(--border-grey);
    outline: none;
    background-color: var(--white);
    font-size: 16px;
    border-radius: 4px;
    height: 40px;
}

.auth-submit {
    padding: 6px;
    background-color: var(--blue);
    color: var(--white);
    border-radius: 4px;
    box-shadow: 1px 1px 1.5px var(--shadow-grey);
    margin-bottom: 15px;
    font-size: 16px;
    width: 100%;
    font-weight: 550;
    height: 40px;
}

.auth-submit:hover {
    background-color: var(--dark-blue);
}

.auth-form-footer {
    text-align: center;
}

.cancel-form:hover {
    color: var(--text-dark-grey);
}

.input-container {
    position: relative;
}

.show-password {
    background-color: var(--white);
    border: none;
    display: inline-block;
    right: 8px;
    top: 8px;
    height: 24px;
    position: absolute;
    font-size: 15px;
}

