.top-nav {
    height: 75px;
    border-bottom: solid 1px #dadce0;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    background-color: var(--white);
}

.nav-right-icon-div {
    float: right;
    position: absolute;
    top: 0;
    right: 20px;
}

.nav-left-icon-div {
    float: left;
    position: absolute;
    top: 0;
    left: 20px;
}

.nav-icon-container {
    height: 75px;
    position: relative;
    width: 68px;
    display: inline-block;
    color: var(--blue-black);
}

.nav-left-icon-div .nav-icon-container {
    margin-right: 10px;
}

.nav-right-icon-div .nav-icon-container {
    margin-left: 10px;
}

.nav-icon-container:hover {
    background-color: var(--link-background-grey);
}

.nav-icon-link {
    position: absolute;
    top: 15px;
    left: 18px;
}

.nav-icon-text {
    position: absolute;
    font-size: 13px;
    top: 44px;
}

.nav-alert-icon {
    position: absolute;
    top: 21px;
}

.nav-alert-text {
    position: absolute;
    top: 24.5px;
    left: 40px;
    width: 155px;
    opacity: 0.6;
}

.vertical-line {
    width: 1px;
    height: 40px;
    background: var(--border-grey);
    opacity: 0.8;
    display: inline-block;
}