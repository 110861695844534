#auth-nav {
    margin-bottom: 85px;
    text-align: center;
}

#auth-nav-link {
    display: block;
    width: 148px;
    margin: 0 auto;
}

#auth-nav-logo {
    height: 60px;
    margin: 0 auto;
    display: block;
    padding-top: 10px;
}






