.modal-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999999;
    background: rgba(128,128,128,0.7);
    width: 100%;
    height: 100%;
}

.floorplans-card {
    width: 350px;
    box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
    padding: 24px;
    border-radius: 4px;
    margin: 20px auto;
    background: var(--white);
    position: relative;
}

.floorplan-list-card {
    background-color: white;
    opacity: 1;
    max-width: 302px;
    box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
    padding: 5px 8px 5px 8px;
    border-radius: 4px;
    height: 50px;
    background-color: var(--white);
    border: solid 1px var(--border-grey);
    margin: 0 auto 15px auto;
    position: relative;
}

.floorplan-list-summary {
    line-height: 1.9;
    height: 100%;
    display: block;
    overflow: hidden;
    padding-top: 4px;
}

.floorplan-list-name {
    font-size: 16px;
    white-space: nowrap;
    font-weight: 600;
    margin: 0;
}

.floorplan-delete {
    margin-left: 10px;
}

.floorplan-delete img {
    width: 29px;
}

.floorplan-icon-div {
    float: right;
    padding-top: 4px;
    width: 35px;
    text-align: right;
    height: 100%;
}
