
#message-wrapper {
    margin: 0 auto;
    width: 370px;
    margin-top: 85px;
    width: 350px;
    box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
    padding: 24px;
    border-radius: 4px;
    background: var(--white);
}

#message-footer {
    text-align: center;
}

#cancel-message {
    color: var(--blue)
}

#cancel-message:hover {
    color: var(--dark-blue);
    font-weight: 500;
}

#message-background {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(128,128,128,0.7);
    width: 100%;
    height: 100%;
  }
