#project-setup-nav {
    margin-bottom: 40px;
    text-align: center;
    padding-top: 25px;
}

#project-setup-title {
    color: var(--blue-black);
    font-weight: 500;
    font-size: 18px;
}

#project-setup-projects-text {
    left: 9px;
}

#right-projects-divider {
    margin: 0 -2px 17px 0px;
}

